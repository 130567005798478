<template>
    <b-card title="Community Map View" style="height: 100%;">
      <div id="map" style="
      position: relative;
      width: 100%;
      height: 68vh;">
        <!-- <VueMapbox 
         :accessToken="accessToken" :mapStyle.sync="mapStyle" /> -->
      </div>
      <b-sidebar
        id="Profile-View"
        ref="Profile-View"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        width="30%"
        title="Employee Profile"
        no-close-on-backdrop
      >
        <template>
          <!-- Header -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-2
              border-bottom
              sticky-top
            "
          >
            <h3 class="mb-0 font-weight-bold">Employee Profile</h3>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="hideProfile()"
              >
                <feather-icon 
                size="20" icon="XCircleIcon" />
              </b-button>
            </div>
          </div>
    
          <!-- BODY -->

          <b-card
            :img-src="require('@/assets/images/banner/banner-'+selected_user.banner_id+'.jpg')"
            img-alt="Profile Cover Photo"
            img-top
            class="card-profile"
          >
            <div class="profile-image-wrapper ">
              <div class="profile-image p-0">
                <b-avatar
                  size="114"
                  variant="light"
                  :src="selected_user.avatar"
                />
              </div>
            </div>
            <h3>{{selected_user.name}}</h3>
            <h6 class="text-muted">
              {{selected_user.email}}
            </h6>
            <b-badge
              class="profile-badge"
              variant="light-primary"
            >
            {{selected_user.designation}}
            </b-badge>
            <hr class="mb-2">

            <!-- follower projects rank -->
              <div>
                <b>Employee Details</b>
                <b-list-group class="mt-1">
                  <b-list-group-item >
                    <b-row >
                      <b-col cols="4" >
                        Bio : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.bio }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Date of Join :
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.date_of_join }}
                      </b-col>
                    </b-row> 
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Employee ID : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.employee_id }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Reporting Manager : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.manager }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Zircly Role : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.role }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Division : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.division }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Department : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.department }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Shift : 
                      </b-col>

                      <b-col cols="8">
                        {{ selected_user.shift }}
                        <span>
                          ({{ selected_user.shift_details.session_1_start_from | formatTime }} -
                          {{ selected_user.shift_details.session_2_end_at | formatTime }})
                        </span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
          </b-card>
        </template>
      </b-sidebar>
    </b-card>

</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';
import { cpuUsage } from "process";
import axios from "@axios";

import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import { AlertTriangleIcon } from "vue-feather-icons";
import $ from "jquery";
Vue.use(VueMapbox, { mapboxgl: Mapbox });

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
    VueMapbox,
    Mapbox
  },
  directives: {
    Ripple,
  },
  setup(){
  },
  methods: {
    createMap(){
      const mapbox = Mapbox;
      mapbox.accessToken = this.accessToken;
      const map = new mapbox.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/dark-v11',
        zoom: 4,
        center:[78.962883,20.593683]
      }) ;
      map.on('load', () => {

        // Add markers to the map.
        for (const marker of this.mapPoints.features) {
        // Create a DOM element for each marker.
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(`+marker.user.avatar+`)`;
        el.style.width = `50px`;
        el.style.height = `50px`;
        el.style.backgroundSize = '100%';
        el.title = marker.user.name+"("+marker.user.email+")";
        el.addEventListener('click', () => {
            $('#Profile-View').show();
            this.selected_user = marker.user;
            this.selected_user.banner_id = marker.banner_id;
        });
        
        // Add markers to the map.
        new mapbox.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map);
        }
      });
    var geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent('United States of America')}.json?access_token=${this.accessToken}`;

    fetch(geocodingUrl)
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          var coordinates = data.features[0].center;
          map.flyTo({
            center: coordinates,
            zoom: 4, // Adjust zoom level for a better country view
            essential: true // This animation is considered essential with respect to prefers-reduced-motion
          });
        } else {
          console.log('Country not found.');
        }
      })
      .catch(error => console.error('Error fetching geocoding data:', error));
  },
    getEmployeesLocation(){
      axios
        .get("/employees/locations")
        .then((response) => {
          if (response.data.success) {
            this.mapPoints = response.data.data;
            this.createMap();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Unable to fetch Employees Location",
                icon: "UserXIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
        });
    },
    hideProfile(){
      $('#Profile-View').hide();
    }
  },
  data() {
    return {
        accessToken: 'pk.eyJ1IjoiZ3VuYXNlZWxhbjAwIiwiYSI6ImNsYnpmODJodzEyOTIzb3FvcXRlbGlhdHYifQ.ybUHMPeuckTZxRNcBvr3uQ', // your access token. Needed if you using Mapbox maps
        mapStyle: 'mapbox://styles/gunaseelan00/clc01pz1h000c15mg5zthwdca',
        map:null,
        selected_user:{
          banner_id:12,
          shift_details:{
            session_1_start_from:"00:00",
            session_2_end_at:"00:00",
          }
        },
        mapPoints : {}
        // {
        //         'type': 'FeatureCollection',
        //         'features': [
        //         {
        //         'type': 'Feature',
        //         'properties': {
        //         'message': 'Foo',
        //         'iconSize': [60, 60]
        //         },
        //         'geometry': {
        //         'type': 'Point',
        //         'coordinates': [78.962883,20.593683]
        //         }
        //         },
        //         ]
        //         },//'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
    };
  },
  mounted() {
     this.getEmployeesLocation(); 
  },
};
</script>
<style>
[data-ctrl-n-menu-id] circle {
  fill: #38B22D;
}
[data-n-id] rect {
  fill: #38B22D;
}
[data-l-id] path {
  stroke: #000000;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
  @import "https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css";

  .marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
  }
</style>